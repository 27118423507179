import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Card,
  Accordion,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import { getOrders, updateOrderPaymentStatus, orderCheckBankStatus } from "../../service/orderService";
import { connect } from "react-redux";
import queryString from "query-string";
import { ConeStriped } from "react-bootstrap-icons";

class AdminOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrders: this.props.orders,
      showPaymentStatus: false,
      showModal: false,
      selectedOrder: null,
      bankCheckInfo: {}
    };
    this.onShowPaymentStatusAlert = this.onShowPaymentStatusAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
    this.beautifyNames = this.beautifyNames.bind(this);
    this.checkBank = this.checkBank.bind(this);
    this.bankCheckTable = this.bankCheckTable.bind(this);
  }

  onShowPaymentStatusAlert = () => {
    this.setState({ showPaymentStatus: true }, () => {
      window.setTimeout(() => {
        this.setState({ showPaymentStatus: false });
      }, 1115000);
    });
  };

  onCloseAlert = () => {
    this.setState({ showPaymentStatus: false });
  };

  // onCloseOrderModal =()=>{

  // }

  onOpenOrderModal = (selected) => {
    this.setState({ selectedOrder: selected });
    this.setState({ showModal: true });
    this.setState({ bankCheckInfo: {} })
  };


  bankCheckTable = (data) => {
    let bankKeys = Object.keys(data)
    let bankValue = Object.values(data)
    let dataSet = ''

    return bankKeys.map((da, index) => {
        return <div key={index} className="d-flex align-items-start" style={{backgroundColor: da === "TransactionStatusCode"? "#0072BB":"#ffffff" }}><Col md={6} className="price-col">{da}</Col> <Col md={6} className="price-col">{bankValue[index]}</Col></div>
      })


  }

  checkBank = async (orderToken) => {
 
    // WORKING ON:
    if (orderToken) {
      const get_bank_status = await orderCheckBankStatus(this.props.user.access_token, orderToken)

      console.log(get_bank_status)
      if (get_bank_status.status === 200) {
        this.setState({ bankCheckInfo: get_bank_status.data })
      } else { console.log('error') }
    }
  }

  beautifyNames = (categoryName, productName) => {
    switch (categoryName) {
      case "Deep Fried":
      case "Burgers":
      case "Sweet & Sour OR Lemon":
      case "Black bean OR Black Pepper":
      case "Chef's Specials":
      case "Chinese Meal Combo":
      case "Special Pack":
        return productName;
      default:
        return productName + " " + categoryName;
    }
  }


  componentDidMount() {
    // const values = queryString.parse(this.props.location.search);

    // if (values.response) {
    //   this.onShowPaymentStatusAlert();
    //   let status = "";
    //   if (values.response === "Failure") {
    //     status = {
    //       payment: { poli: true },
    //       payment_status: "Failure",
    //       payment_recived: "false",
    //     };
    //   } else if (values.response === "Success") {
    //     status = {
    //       payment: { poli: true },
    //       payment_status: "Success",
    //       payment_recived: "true",
    //     };
    //   } else {
    //     status = {
    //       payment: { poli: true },
    //       payment_status: "Cancelled",
    //       payment_recived: "false",
    //     };
    //   }

    //   await updateOrderPaymentStatus(values.id, status).then(
    //     (res) => {
    //       setTimeout(function () {
    //         window.location.href = '/orders'
    //       }, 5000);
    //     },
    //     (rej) => {
    //       window.location.href = '/orders'
    //     }
    //   );
    // }

    // await getOrders(this.props.user.userId).then(
    //   (res) => {
    //     // console.log(`res.string`, res.stringify())
    //     this.setState({
    //       allOrders: res.sort(
    //         (a, b) => new Date(b.order_date) - new Date(a.order_date)
    //       ),
    //     });
    //   },
    //   (rej) => { }
    // );
  }


  render() {
    let sum = 0;
    // const qvalue = queryString.parse(this.props.location.search);
    return (
      <Container >

        <br />
        <h2>Your Recent Orders</h2>
        <br />
        <hr />
        <Row className="row-buttom" style={{ margin: 0, padding: 0 }}>
          <Col xs={5} style={{ textAlign: "center" }}>
            <label className="h8">Date:</label>
          </Col>
          <Col xs={2} style={{ textAlign: "center" }}>
            <label className="h8">ID:</label>
          </Col>
          <Col xs={2} style={{ textAlign: "center" }}>
            <label className="h8">Cost:</label>
          </Col>
          <Col xs={2} style={{ textAlign: "center" }}>
            <label className="h8">Payment Status:</label>
          </Col>
        </Row>

        {this.state.allOrders.map((order, index) => {
          return (
            <Row
              key={index}
              className="row-buttom"
              onClick={() => this.onOpenOrderModal(order)}
              style={{
                backgroundColor:
                  order.payment_status === "Cancelled"
                    ? "#fbe9e7"
                    : order.payment_status === "ready"
                      ? "#fffde7"
                      : order.payment_status === "Success"
                        ? "#c8e6c9"
                        : order.payment_status === "Failure"
                          ? "#ffebee"
                          : "#e0e0e0",

              }}
            >
              <Col xs={5} style={{ textAlign: "center" }} >
                <label className="h10">
                  {new Date(order.order_date).toLocaleString(
                    "en-NZ",
                    "New Zealand/Auckland"
                  )}
                </label>
              </Col>
              <Col xs={2} style={{ textAlign: "center" }}>
                <label className="h9">{"#" + order.order_no}</label>
              </Col>
              <Col xs={2} style={{ textAlign: "center" }}>
                <label className="h9"> {"$" + order.total_charge.toFixed(2) / 100}</label>
              </Col>
              <Col xs={2} style={{ textAlign: "center" }}>
                <label className="h9">{order.payment_status}</label>
              </Col>
            </Row>
          );
        })}

        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation
          scrollable
        >
          <Modal.Header className="d-flex justify-content-between" closeButton>
            <Modal.Title>
              {this.state.selectedOrder
                ? "Order ID #" + this.state.selectedOrder.order_no
                : ""}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid" >
            <Container>
              <>
                {this.state.selectedOrder &&
                  this.state.selectedOrder.data.map((item, index) => {
                    sum += Object.values(item)[0].price / 100;
                    return (

                      <Row
                        key={index}
                        className="mt-2"
                        style={cartStyle}
                      >
                        <Col
                          xs={9}
                          style={{
                            backgroundColor: "#fff",
                            padding: 0,
                            margin: 10,
                            borderRadius: 10,
                            border: '1px solid rgba(255, 255, 255, 0.9)',
                          }}
                        >
                          <Row>
                            <Col className="d-flex justify-content-between">
                              <h6>{this.beautifyNames(Object.values(item)[0].category, Object.values(item)[0].name)}</h6>
                              {"   $" + Object.values(item)[0].price / 100}
                            </Col>
                          </Row>
                        </Col>

                      </Row>
                    );
                  })}
              </>
              <br />
              <p style={{ fontSize: 14, color: "#aaa" }}>**[Payment: ready] Means <u>not been process by user</u> or payment session timeout.</p>

              {// WORKING ON:
              }
              <Button onClick={() => this.checkBank(this.state.selectedOrder.access_token ? this.state.selectedOrder.access_token : '')} > Check Bank Info </Button>
              {this.state.bankCheckInfo? this.bankCheckTable(this.state.bankCheckInfo) : <p>no data</p>}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {this.state.selectedOrder && (
              <p>
                {new Date(this.state.selectedOrder.order_date).toLocaleString(
                  "en-NZ",
                  "New Zealand/Auckland"
                )}
              </p>
            )}

            {this.state.selectedOrder && (
              <p
                style={{
                  backgroundColor:
                    this.state.selectedOrder.payment_status === "Cancelled"
                      ? "#ffab91"
                      : this.state.selectedOrder.payment_status === "ready"
                        ? "#fff59d"
                        : this.state.selectedOrder.payment_status === "Success"
                          ? "#81c784"
                          : this.state.selectedOrder.payment_status === "Failure"
                            ? "#ef9a9a"
                            : "#e0e0e0",
                }}
              >
                {"Payment: " + this.state.selectedOrder.payment_status}
              </p>
            )}
            {this.state.selectedOrder && (
              <p >
                {"$" + this.state.selectedOrder.total_charge.toFixed(2) / 100}
              </p>
            )}
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

const cartStyle = {
  marginTop: '0.8rem !important',
  minHeightheight: 120,
  backgroundColor: "#FDFFFC",
  padding: 0,
  margin: '10px !important',
  borderRadius: 8,
  border: '1px solid rgba(0, 0, 0, 0.05)',
  boxShadow: "0 3px 10px rgb(0 0 0 / 0.3)"
};

function mapStateToProps(state) {
  return {
    user: state.AuthReducer,
  };
}
export default connect(mapStateToProps)(AdminOrderList);
