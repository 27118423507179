import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Spinner } from 'react-bootstrap'
import renderTextBox from '../components/inputs/renderTextBox'

class SetPassForm extends Component {

  required = (value) => (value ? undefined : 'This field is required');
  
  maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined;


  email = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined;

  validPass = (value) =>  value && !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/i.test(value)
            ? 'Invalid pass word'
            : undefined;

  renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
    <div>
      <label>{label}</label>
      <div>
        <input {...input} placeholder={label} type={type} />
        <br />
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  )

  render() {

    const { error, handleSubmit, submitting } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <h3>Recover Password</h3>
        <br />
        <Field
          name="newPassword"
          type="password"
          component={this.renderField}
          label="New Password"
          validate={[this.required, this.validPass]}
        />
        {error && <strong>{error}</strong>}
        <br />
        <Button type="submit"
          disabled={submitting}>
          {submitting ? <Spinner animation="border" variant="danger" /> : "Confirm"}
        </Button>
      </Form>
    )
  }
}
export default reduxForm({
  form: 'SetPassForm' // a unique identifier for this form
})(SetPassForm)
