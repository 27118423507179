import React, { Component } from 'react'
import { Field, reduxForm, FieldArray } from 'redux-form'
import renderTextBox from './renderTextBox'
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap'
import { PlusCircle, DashCircle, XCircle } from 'react-bootstrap-icons';


class CreateProduct extends Component {
    constructor(props) {
        super(props)
        
    }

    render() {
        const { error, handleSubmit, submitting, categoies, productActive } = this.props

        const renderField = ({ input, label, type, meta: { touched, error } }) => (
            <>
                <input {...input} type={type} placeholder={label} />
                {touched && error && <span>{error}</span>}
            </>
        );

        const renderIncluded= ({ fields, meta: { error } }) => (
            <>
                <label> For Combo products, add included products. eg: $12 pack, included( 2 fish, 1chips) </label>
                <div style={{ marginBottom:20 }}>
                    <Button type="button" onClick={() => fields.push()}>Add Included Product</Button>
                    <br/>
                </div>
                {fields.map((menu, index) => (

                    <div key={index} className='d-flex row' style={{ backgroundColor: "#8aabd9" }} >
                        <Col md={4} className="align-content-sm-center">
                            <Field
                                name={menu}
                                type="text"
                                component={renderField}
                            />
                        </Col>
                        <Col md={2} className="align-text-bottom m-2">
                            <Button
                                size="sm"
                                type="button"
                                onClick={() => fields.remove(index)}
                            >
                                <XCircle size={16} />
                            </Button>
                        </Col>

                    </div>

                ))}
                {error && <li className="error">{error}</li>}
            </>
        )

        const renderConfig= ({ fields, meta: { error } }) => (
            <>
                <label> product config eg: Chips default come with Salt, So put Salt in the box for customer able to have no Salt. </label>
                <div style={{ marginBottom:20 }}>
                    <Button type="button" onClick={() => fields.push()}>Add Product Configable</Button>
                    <br/>
                </div>
                {fields.map((config, index) => (

                    <div key={index} className='d-flex row' style={{ backgroundColor: "#578c6e" }} >
                        <Col md={4} className="align-content-sm-center">
                            <Field
                                name={config}
                                type="text"
                                component={renderField}
                            />
                        </Col>
                        <Col md={2} className="align-text-bottom m-2">
                            <Button
                                size="sm"
                                type="button"
                                onClick={() => fields.remove(index)}
                            >
                                <XCircle size={16} />
                            </Button>
                        </Col>

                    </div>

                ))}
                {error && <li className="error">{error}</li>}
            </>
        )

        return (
            <Form onSubmit={handleSubmit}>
                <br />
                <label>Category</label>
                <br />
                <Field name="CategoryName" component="select">

                    {categoies && categoies.map((cat, idx) => {
                        if (idx !== 0 )
                            return <option key={idx} value={cat.name} defaultValue={idx === 1 ? "selected" : ''}>{cat.name}</option>
                    })}
                </Field>
                <Field
                    name="name"
                    type="text"
                    component={renderTextBox}
                    label="Name"
                />

                <Field
                    name="description"
                    type="text"
                    component={renderTextBox}
                    label="Description"
                />

                <Field
                    name="price.regular"
                    step="0.01"
                    min="0"
                    pattern="^\d+(?:\.\d{1,2})?$"
                    component={renderTextBox}
                    label='Price 5.5'
                />

                <Field
                    name="display_level.menu"
                    type="number"
                    component={renderTextBox}
                    label='Menu Display Order (Integer only)'
                />

                {/* <Field
                    name="status.active"
                    type="text"
                    component={renderTextBox}
                    label='status'
                /> */}

<br/>
                <Form.Switch // prettier-ignore
                    name="status.active"
                    onChange={productActive}
                    type="switch"
                    id="status-switch"
                    label="Show on menu"
                    // defaultChecked={true}
                    
                />
                <br />
                <div style={{ borderRadius: 16, borderWidth: 2, backgroundColor: "#d1d1d6", margin: 20, padding: 20 }}>
                    <FieldArray name="configer" component={renderConfig} />
                </div>
                {/* <Field
                    name="include_products"
                    type="text"
                    component={renderTextBox}
                    label="Include Product{-}"
                /> */}
                <br />
                <div style={{ borderRadius: 16, borderWidth: 2, backgroundColor: "#d1d1d6", margin: 20, padding: 20 }}>
                    <FieldArray name="include_products.menu" component={renderIncluded} />
                </div>
               

                <br />
                <label>Product Extra Menu, eg: Hotdog with add Tomato souerce</label>
                <br />

                <Field name="add_on_menu" component="select" >
                    <option defaultValue={"selected"}></option>
                    {categoies && categoies.map((cat, idx) => {
                        if (idx !== 0 && cat.status === "disable")
                            return <option key={idx} value={cat.name} >{cat.name}</option>
                    })}
                </Field>

                <br />


                <br />

                {error && <strong>{error}</strong>}
                <Button type="submit" disabled={submitting}
                    style={{ marginTop: 10 }}
                >
                    {submitting ? <Spinner animation="border" variant="danger" /> : "Save"}
                </Button>
            </Form>
        )
    }
}
export default reduxForm({
    form: 'CreateProduct' // a unique identifier for this form
})(CreateProduct)
