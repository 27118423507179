import React, { Component } from "react";
import { Col, Row, Card, Button, Container } from "react-bootstrap";
import { recoverAccount } from "../service/authService";
import RecoverForm from "./recoverForm";

import Modal from 'react-bootstrap/Modal';

class Recovering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignUp: false,
      status: "",
      show:false
    };
  }

  handelCloseModal = () => {
    this.setState({ show: !this.state.show });
    const { history } = this.props;
    history.push('/'); // Redirect to the About page
  };



  recover = (values) => {
  return recoverAccount(values).then(
        (result) => {
        // console.log("result", result)
        if (result.status === 204 || result.status === 200){
          this.setState({ status: "An recovery email has been sent, click the link in email to set new password" });
          this.setState({show: true})
        }
      }
    ).catch(err =>{
        this.setState({ status: "email passsword error, try again please!" });
    })
  }

  render() {
    return (
      <Container>
        <Row>
          <div className="whiteSpace"></div>
          <Col md></Col>
          <Col md style={{ flexDirection: "row", alignItems: 'center' }}>
            <RecoverForm onSubmit={(values, dispatch) => this.recover(values, dispatch)} />
          </Col>
          <Col md></Col>
        </Row>
        <Row>
          <div className="whiteSpace">

          </div>
        </Row>

        <Modal show={this.state.show} onHide={this.handelCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Recovery Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.state.status}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handelCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      </Container>
    );
  }
}

export default Recovering
