import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Spinner } from 'react-bootstrap'
import renderTextBox from '../components/inputs/renderTextBox'

class RecoverForm extends Component {

  required = (value) => (value ? undefined : 'This field is required');

  email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
  
    renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
      <div>
        <label>{label}</label>
        <div>
          <input {...input} placeholder={label} type={type}/>
          <br/>
          {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      </div>
    )

  render() {
    const { error, handleSubmit, submitting } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <h3>Recover Password</h3>
        <br/>
        <Field
          name="email"
          type="email"
          component={this.renderField}
          label="Email"
          validate={[this.required, this.email]}
        />
        {error && <strong>{error}</strong>}
        <br/>
        <Button type="submit"
          disabled={submitting}>
          {submitting ? <Spinner animation="border" variant="danger" /> : "Confirm"}
        </Button>
      </Form>
    )
  }
}
export default reduxForm({
  form: 'RecoverForm' // a unique identifier for this form
})(RecoverForm)
