
import axios from 'axios';

axios.defaults.baseURL = 'https://efun.co.nz/flying/api';


export const postOrder = (order) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/Orders',
      headers: {
        'Content-Type': 'application/json'
      },
      data: order
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err.response);
      });
  });
}



export const getOrders = (userId) => {
  let url = '/users/' + userId + '/Orders';

  axios.interceptors.request.use(request => {
    // console.log('Starting Request', JSON.stringify(request, null, 2))
    return request
  })


  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        // console.log(`service res`, res)
        resolve(res.data);
      })
      .catch(err => {
        // console.log(`service res`, err)
        reject(err.response);
      });
  });
}

export const updateOrderPaymentStatus = (order_id, status) => {

  return new Promise((resolve, reject) => axios.patch('/Orders/' + order_id, status)
    .then(res => {
      resolve(res);
    })
    .catch(err => reject(err))
  )
}

export const orderCheckBankStatus= (access_token,nugde_token) =>{

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: "https://efun.co.nz/flying/api/Orders/checkorder?access_token="+access_token+"&orderToken="+nugde_token,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });

}

//?filter[where][date][gt]=2014-04-10T18:30:00.000Z
export const adminGetOrders = (filter) => { 
  // {"where": {"order_date": {"gt": "2021-11-01T00:00:00.000Z"}}}
  // working '/Orders?filter[where][order_date][gt]=2023-04-10T18:30:00.000Z'
  //'/Orders?filter[where][order_date][gt]=2024-10-01T18:30:00.000Z&filter[where][order_date][lt]=2024-11-01T18:30:00.000Z'
  return new Promise((resolve, reject) => axios.get('/Orders'+filter)
    .then(res => {
      if (res.status === 400 || res.status === 403 || res.status === 401 || res.status === 422) {
        reject(res);
      }
      resolve(res.data);
    })
    .catch(err => reject(err))
  )
}