import React, { Component } from "react";
import {
  Button,
  Modal,
  ListGroup,
  Form,
  Spinner,
  Container,
  Row,
  Col,
  Alert,
  ButtonToolbar,
  ButtonGroup,
  Badge
} from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import renderTextarea from "../components/inputs/rederTextarea";
import DeleteConfirmationModal from "../components/modals/DeleteConfirmationModal";
// import { PURGE } from "redux-persist";
// import uuid from "react-uuid";
import { Redirect } from "react-router-dom";
// import Iframe from "react-iframe";
import { PlusCircle, DashCircle, XCircle } from 'react-bootstrap-icons';
import { uploadCartSuccess } from "../actions/cartActions";
import { addToCart, removeFromCart, removeGroupFromCart, updateCartSetting } from "../actions/cartActions";

import { postOrder } from "../service/orderService";

import { uniqBy } from 'lodash';
import uuid from "react-uuid";
import { getUserName } from "../service/authService";

import { adminGetOrders } from "../service/orderService";

class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      showUserErrorAlert: false,
      showOrderSubmitedAlert: false,
      showPaymentError: 0,
      onInitPaymentSuccessRedirect: "",
      onSumCheck: false,
      setEnableToOrder: false,
      enableToOrderModal: false, //HACK: time disable opening box
      loginErrorModal: false,
      setDeleteDialog: false,
      deleteCartItem: {},
      isAdmin: false
      // orderSendModal:false
    };
    this.handleOrderSubmit = this.handleOrderSubmit.bind(this);
    this.onPurgeStoredState = this.onPurgeStoredState.bind(this);
    this.onShowUserErrorAlert = this.onShowUserErrorAlert.bind(this);
    this.onShowOrderSubmitedAlert = this.onShowOrderSubmitedAlert.bind(this);
    this.sumCheck = this.sumCheck.bind(this);

    this.reOrderMyCart = this.reOrderMyCart.bind(this);

    this.beautifyNames = this.beautifyNames.bind(this);

    this.removeGroupItemsFromCart = this.removeGroupItemsFromCart.bind(this)
    this.addItemToCart = this.addItemToCart.bind(this)

    this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this)
    this.handleDeleteDialogOpen = this.handleDeleteDialogOpen.bind(this)

    this.updateProductItemSetting = this.updateProductItemSetting.bind(this)

    this.handleConfirmDelete = this.handleConfirmDelete.bind(this)

  }

  onPurgeStoredState() {
    this.props.uploadCartSuccess();
    localStorage.removeItem("persist:shorpping_cart");
    // window.location.replace("https://flyingdragon.co.nz")
  }

  handleDeleteDialogClose = () => this.setState({ setDeleteDialog: false });
  handleDeleteDialogOpen = () => this.setState({ setDeleteDialog: true });


  sumCheck(sum) {
    if (sum > 5) {
      // console.log(">5");
      this.setState({ onSumCheck: true });
    } else {
      // console.log("<5");
      this.setState({ onSumCheck: false });
    }
  }

  async componentDidMount() {
    this.openingTimeCheck()

    //TODO: check user admin

    // await getUserName(this.props.userId)
    //   .then((res) => {
    //     this.setState({ isAdmin: res.isAdmin })
    //   })

    // if (this.state.isAdmin)
    //   await adminGetOrders().then((res) => {
    //     console.log('res',res)
    //     this.setState({
    //       allOrders: res,
    //     });

    //   }).catch(err => {
    //     console.log(err)
    //   })


  }

  openingTimeCheck() {

    const opening = [
      [16, 20], // Sunday ...
      [16, 20],
      [16, 20],
      [11.3, 14, 16, 20],
      [11.3, 14, 16, 20],
      [11.3, 14, 16, 20],
      [11.3, 14, 16, 20], // Saturday
    ];

    let current_date = new Date();
    let today = current_date.getDay();

    let openingTimes = opening[today];

    let current_time = Number(
      current_date.getHours() + "." + current_date.getMinutes()
    );

    if (openingTimes.length > 0) {
      for (let index = 0; index < openingTimes.length; index = index + 2) {
        if (openingTimes[index] <= current_time && current_time <= openingTimes[index + 1]) {
          this.setState({ setEnableToOrder: true })
          this.setState({ enableToOrderModal: true })
        }
      }
    }

    // console.log(`openingTimes`, openingTimes)
    // console.log(`current_time`, current_time);
  }

  handleOrderSubmit = async (values, dispatch, total) => {

    let pt = new Date(new Date().getTime() + 20 * 60000);
    let info = { "pickup": pt }

    this.openingTimeCheck();
    values.store = "flyingdragon";
    values.userId = this.props.user.userId;
    values.order_no = Math.floor(Math.random() * 100000);
    values.data = this.props.myCart;
    values.payment_status = "ready";
    values.payment_recived = false;
    values.total_charge = total.toFixed(2) * 100;
    values.order_date = new Date();
    values.shipping_info = info;

    console.log('user', this.props.user)
    console.log(`-values-`, values);
    console.log(this.props.isLoggedIn)
    if (this.props.isLoggedIn) {
      return await postOrder(values).then(
        (res) => {
          // console.log("post return > ", res);
          dispatch(reset("ShoppingCart"));
          console.log(res.data)

          if (res.data.payment_conn.Success) {
            this.setState({
              onInitPaymentSuccessRedirect: res.data.payment_conn.NavigateURL,
            });
          }
          this.onPurgeStoredState();
        },
        (rej) => {
          this.onShowUserErrorAlert();
        }
      );
    } else {
      this.onShowUserErrorAlert();
    }
  };

  removeItemFromCart = (productId) => {
    this.props.removeFromCart(productId)
  }

  addItemToCart = async (product) => {
    this.props.addToCart({ "item": { ...product, session: uuid() } })
  }

  removeGroupItemsFromCart = (productId) => {
    this.props.removeGroupFromCart(productId)
  }

  updateProductItemSetting = (itemSetting) => {
    this.props.updateCartSetting(itemSetting) // { product, config, data}
  }

  onShowUserErrorAlert = () => {
    this.setState({ showUserErrorAlert: true }, () => {
      window.setTimeout(() => {
        this.setState({ showUserErrorAlert: false });
      }, 3000);
    });
  };

  onShowOrderSubmitedAlert = () => {
    this.setState({ showOrderSubmitedAlert: true }, () => {
      window.setTimeout(() => {
        this.setState({ showOrderSubmitedAlert: false });
      }, 3000);
    });
  };

  handleDeleteClick = (item) => {
    console.log('item', item)
    this.setState({ deleteCartItem: item });
    this.handleDeleteDialogOpen()
  };


  handleConfirmDelete = () => {
    // setItems(items.filter((item) => item.id !== itemToDelete.id));
    this.removeGroupItemsFromCart({ id: this.state.deleteCartItem.id, session: this.state.deleteCartItem.session, solo: this.state.deleteCartItem.solo })
    this.setState({ deleteCartItem: {} });
    this.setState({ setDeleteDialog: false })

  };

  beautifyNames = (categoryName, productName) => {
    switch (categoryName) {
      case "Deep Fried":
      case "Burgers":
      case "Sweet & Sour OR Lemon":
      case "Black bean OR Black Pepper":
      case "Chef's Specials":
      case "Chinese Meal Combo":
      case "Special Pack":
        return productName;
      default:
        return productName + " " + categoryName;
    }
  }

  reOrderMyCart = (myCart) => {

    let orderList = []

    let displayList = []

    let group = []

    Object.values(myCart).filter(
      item => {
        // console.log(Object.values(item))
        if (Object.values(item)[0].solo === 'solo')
          displayList.push(Object.values(item)[0])

        if (Object.values(item)[0].solo === 'group')
          group.push(Object.values(item)[0])
      }
    )

    const counts = group.reduce((accumulator, item) => {
      accumulator[item.id] = (accumulator[item.id] || 0) + 1;
      return accumulator;
    }, {});

    let new_display_list = []

    myCart.map((item, index) => {
      Object.keys(counts).map((ids, idx) => {
        if (Object.values(item)[0].solo === 'group' && Object.values(item)[0].id === ids) {
          Object.values(item)[0].count = Object.values(counts)[idx]
          new_display_list.push(Object.values(item)[0])
        }
      })
    })

    const abc = uniqBy(new_display_list, function (e) {
      return e.id;
    });

    return abc.concat(displayList)

  }

  render() {
    const { error, handleSubmit, submitting, myCart } = this.props;
    // console.log('setEnableToOrder << BUTTON >>', this.state.setEnableToOrder)
    // console.log('enableToOrderModal', this.state.enableToOrderModal)
    // console.log('myCart>', myCart)
    let sum = 0;
    const displayReorderedCartList = this.reOrderMyCart(myCart);
    // console.log('displayReorderedCartList', displayReorderedCartList);

    return (
      <>
        <Container className="list-group-container cart-item hide-scrollbar" style={{ padding: 10 }}>
          <div className="m-4" style={{ textAlign: "center" }}>
            <h3> Your Shopping Cart </h3>
          </div>
          {this.state.showPaymentError !== 0 && (
            <>
              <Alert dismissible variant="danger">
                <Alert.Heading>
                  {" "}
                  Ooops!We unable to process your Order!{" "}
                </Alert.Heading>{" "}
                <p> Please call us on 06 7591886 for Pickup. </p>{" "}
                <p> Error: {this.state.showPaymentError}. </p>{" "}
              </Alert>{" "}
            </>
          )}
          {this.state.onInitPaymentSuccessRedirect &&
            (window.location.href =
              this.state.onInitPaymentSuccessRedirect)
            // <Iframe
            //   url={this.state.onInitPaymentSuccessRedirect}
            //   width="100%"
            //   min-height="100%"
            //   id="myId"
            //   className="iframeContainer"
            //   display="block"
            //   position="fixed"
            // />
          }
          {this.state.showUserErrorAlert && (
            <>
              <Alert dismissible variant="danger">
                <Alert.Heading> Ooops!You are not login yet! </Alert.Heading>{" "}
                <p> Login before make an order please. </p>{" "}
              </Alert>{" "}
            </>
          )}
          {this.state.showOrderSubmitedAlert && (
            <>
              <Alert dismissible variant="success">
                <Alert.Heading> Order been sent. </Alert.Heading>{" "}
                <p> Please wait for Emails.And checking your Orders page. </p>{" "}
              </Alert>{" "}
              <Redirect to="/orders" />
            </>
          )}{" "}

          {/* { myCart.filter( (x) => ( Object.values(x)[0].id === selectedProduct.id) && (Object.values(x)[0].solo === false)  ) } */}

          {myCart.length > 0 && (
            <>
              <Col>
                {
                  displayReorderedCartList.map((item, index) => {
                    sum += (item.price * item.count) / 100;
                    {/* WORKING ON: count  product by numbers */ }
                    // console.log(sum,index, item.price, item.count)
                    // if(Object.values(item)[0].solo)
                    return (
                      <Col key={index} style={{ marginTop: 20 }}>
                        <Row
                          className="box-margin"
                          style={{
                            minHeightheight: 120,
                            backgroundColor: "#FDFFFC",
                            padding: 0,
                            borderRadius: 8,
                            border: '1px solid rgba(0, 0, 0, 0.05)',
                            boxShadow: "0 3px 10px rgb(0 0 0 / 0.3)"
                          }}
                        >
                          <Col
                            xs={9}
                            style={{
                              backgroundColor: "#fff",
                              padding: 0,
                              margin: 0,
                              borderRadius: 10,
                              border: '1px solid rgba(255, 255, 255, 0.9)',
                            }}
                          >
                            <Row>
                              <Col className="d-flex justify-content-between">
                                <h6>{this.beautifyNames(item.category, item.name)}</h6>
                                {"   $" + item.price / 100}
                              </Col>
                            </Row>

                            <>
                              {item.configuer?.map(
                                (ingredient, indx) => (
                                  <Row key={indx} style={{ padding: 0, }}>
                                    <Col xs={11} className="d-flex justify-content-between " style={{ borderBottom: '1px dotted rgba(64, 156, 255, 0.7' }}>
                                      <label className="h8">{" No " + Object.values(ingredient)[0]}{" "}</label>
                                      <Button
                                        size="lg"
                                        className="d-inline-flex align-items-center justify-content-start"
                                        style={{ backgroundColor: "#fff", color: 'rgba(253, 95, 156, 0.6)', }}
                                        onClick={() => this.updateProductItemSetting({ item: item, type: 'configuer', selected: Object.values(ingredient)[0] })}
                                      >
                                        <XCircle size={14} />
                                      </Button>
                                    </Col>
                                  </Row>
                                )
                              )}

                              {item.extras?.length > 0 && (
                                item.extras.map(
                                  (xtra, indx) => {
                                    sum += Object.values(xtra)[0].price.regular / 100;
                                    return <Row key={indx} style={{ padding: 0, }}>
                                      <Col xs={11} className="d-flex justify-content-between " style={{ borderBottom: '1px dotted rgba(64, 156, 255, 0.7' }}>
                                        <label className="h8">{" Add " + Object.values(xtra)[0].name}{" "}</label>
                                        <label className="h8">{" $" + Object.values(xtra)[0].price.regular / 100}{" "}</label>

                                        <Button
                                          size="lg"
                                          className="d-inline-flex align-items-center justify-content-start"
                                          style={{ backgroundColor: "#fff", color: 'rgba(253, 95, 156, 0.6)', }}
                                          onClick={() => this.updateProductItemSetting({ item: item, type: 'extras', selected: Object.values(xtra)[0].name })}
                                        >
                                          <XCircle size={14} />
                                        </Button>
                                      </Col>
                                    </Row>
                                  }
                                )
                              )}

                            </>

                          </Col>

                          <Col
                            xs={3}
                            style={{ backgroundColor: "#6A4FDFFFC93", padding: 0 }}
                          >
                            <Row>
                              <Col className="d-flex align-items-center justify-content-end">
                                <Button
                                  size="lg"
                                  className="d-inline-flex align-items-center justify-content-start"
                                  style={{ backgroundColor: "#fff", color: "#2e3a54", }}
                                  onClick={() => this.handleDeleteClick(item)}
                                >
                                  <XCircle size={20} />
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="d-flex align-items-center justify-content-end" style={{ marginTop: 5 }}>
                                <ButtonToolbar >
                                  <ButtonGroup size="sm">
            
                                    <Button
                                      size="lg"
                                      className="d-inline-flex align-items-center justify-content-start"
                                      style={{ backgroundColor: "#fff", color:item.count !== 1? "#2e3a54" :"#d1d1d6"   }}
                                      disabled={item.count !== 1? false : true}
                                      onClick={() => this.removeItemFromCart({ id: item.id, session: item.session })}
                                    >
                                      <DashCircle size={20} />
                                    </Button >

                                    <Badge className="d-inline-flex align-items-center justify-content-start"
                                      style={{ borderRadius: "6px", backgroundColor: "rgba(255, 159,10, 0.8)", color: "#1c1c1e", margin: 4, fontSize: '14px' }} >
                                      {item.count}
                                    </Badge>

                                    <Button
                                      size="lg"
                                      className="d-inline-flex align-items-center justify-content-start"
                                      style={{ backgroundColor: "#fff", color: "#2e3a54", }}
                                      onClick={() => this.addItemToCart(item)}
                                    >
                                      <PlusCircle size={20} />
                                    </Button>
                                  </ButtonGroup>
                                </ButtonToolbar>
                              </Col>
                            </Row>
                            <Row style={{ height: 8, width: 10, backgroundColor: "#fff", marginLeft: `10px !important`, borderRadius: 10, border: '1px solid rgba(255, 255, 255, 0.1)' }} ></Row>
                          </Col>
                        </Row>
                      </Col>
                    )
                  })
                }

                <br />
                <div style={{ textAlign: "center", fontSize: 22, color: "#1E1C34", fontWeight: 800 }}>
                  <label>Total: {"$" + sum.toFixed(2) + " incl gst"}</label>
                </div>
                <hr />
                <hr />

                <Form
                  onSubmit={handleSubmit((values, dispatch) =>
                    this.handleOrderSubmit(values, dispatch, sum)
                  )}
                  style={{ textAlign: "center" }}
                >
                  <div className="mr-auto">
                    <Form.Label> Order Notes : minimum order $10.00</Form.Label>{" "}
                    <Field name="note" component={renderTextarea} />{" "}
                  </div>
                  <br />
                  <Row >
                    <Col className="" >
                      <Button
                        variant="secondary"
                        onClick={() => this.onPurgeStoredState()}
                        className="box-margin"
                      >
                        {" "}
                        Delete Order{" "}
                      </Button>

                      <Button
                        variant={this.state.setEnableToOrder ? "primary" : "danger"}
                        type="submit"
                        disabled={submitting || !this.state.setEnableToOrder}
                        className="box-margin"
                      >
                        {" "}
                        {this.state.setEnableToOrder ? (
                          "Agree And Make Order"
                        ) : (
                          "Not Open Yet"
                        )}{" "}

                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  {error && <strong> {error} </strong>}
                  <p>Order Must be Pick Up from Flying Dragon Takeaways </p>
                  <p>
                    <a
                      href="https://goo.gl/maps/t2Zxsnyu7A63cpvr5"
                      target="_blank" rel="noreferrer"
                    >
                      37 Tukapa Street Westown, New Plymouth. Phone:759-1886{" "}
                    </a>
                  </p>
                  <p>
                    We only accept orders within Taranaki Region New Zealand!
                  </p>
                  <p>No refun apply to any orders, only credit to account. </p>
                  <br />


                  <p>
                    {" "}
                    <a href="https://www.polipay.co.nz/" target="blank">
                      {" "}
                      HTTPS Secure payment will process by our internet banking
                      provider POLI payment.{" "}
                    </a>
                  </p>
                </Form>{" "}
              </Col>{" "}
              <Col />
            </>
          )}
          {myCart.length <= 0 && (
            <>
              <Col />
              <Col>
                <h5> Shoping Cart has no items </h5>{" "}
              </Col>{" "}
              <Col />
            </>
          )}{" "}
        </Container>

        <Modal
          show={this.state.showUserErrorAlert}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered

        >
          <Modal.Header>
            <Modal.Title>Can not Order!</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Ooops!We unable to process your Order!</p>
            <p> Login before make an order please. </p>{" "}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showPaymentError !== 0}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Can not Order!</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Ooops!We unable to process your Order!</p>
            <p> Please call us on 06 7591886 for Pickup. </p>{" "}
            <p> Error: {this.state.showPaymentError}. </p>{" "}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showOrderSubmitedAlert}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Order been sent!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p> Please wait for Emails.And checking your Orders page. </p>
          </Modal.Body>
        </Modal>

        <Modal
          show={!this.state.enableToOrderModal}
          onHide={() => this.setState({ enableToOrderModal: true })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>We are not open yet.</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h3>Today: {new Date().toLocaleString(
              "en-NZ",
              "New Zealand/Auckland"
            )}
            </h3>
            <h5>Sorry, We are not open yet.</h5>
            <h5>We can not process your order, come back on below time. </h5>
            <Row style={{ justifyContent: "center" }}>
              <h5 >Lunch : Wednesday to Saturday 11:30am-2:00pm </h5>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <h5>Dinner : Monday to Sunday 4:00pm-9:00pm</h5>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <h5 style={{ color: '#FF101F', backgroundColor: '#ff9f0a' }}>Online Orders only open up to 8PM</h5>
            </Row>

          </Modal.Body>
        </Modal>

        <DeleteConfirmationModal
          show={this.state.setDeleteDialog}
          handleClose={this.handleDeleteDialogClose}
          handleConfirm={this.handleConfirmDelete}
          item={this.state.deleteCartItem}
        />

      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    myCart: state.shoppingCart.myCart,
    user: state.AuthReducer,
    userId: state.AuthReducer.userId,
    isLoggedIn: state.AuthReducer.isLoggedIn,
    access_token: state.AuthReducer.access_token,
    // ()=>this.removeGroupItemsFromCart({id:item.id, session:item.session, solo:item.solo})
  };
}
function mapDispatchToProps(dispatch) {
  return {
    uploadCartSuccess: () => dispatch(uploadCartSuccess()),
    addToCart: (product) => dispatch(addToCart(product)),
    removeFromCart: (productId) => dispatch(removeFromCart(productId)),
    removeGroupFromCart: (productId) => dispatch(removeGroupFromCart(productId)),
    updateCartSetting: (product) => dispatch(updateCartSetting(product))
  };
}

ShoppingCart = connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);

export default reduxForm({
  form: "ShoppingCart", // a unique identifier for this form
})(ShoppingCart);
