import React, { Component } from "react";
import { Col, Row, Card, Button, Container } from "react-bootstrap";
import { recoverAccount } from "../service/authService";
import axios from 'axios';

import Modal from 'react-bootstrap/Modal';
import SetPassForm from "./setpassForm";

class Recover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignUp: false,
      status: "",
      show: false,
      token: ""
    };
  }

  handelCloseModal = () => {
    this.setState({ show: !this.state.show });
    const { history } = this.props;
    history.push('/profile'); // Redirect to the About page
  };

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.setState({ token: params.get('access_token') });

  }

  recover = async (values) => {
    console.log(values)
    console.log(this.state.token)

    if (this.state.token && this.state.token.length > 10) {
      // fetch post the new password
      let sendInfo = await axios({
        method: 'POST',
        url: 'https://efun.co.nz/flying/api/users/reset-password',
        headers: {
          "Accept": "application/json",
          'Content-Type': 'application/json',
          'Authorization': this.state.token
        },
        data: values
      })

      let response = sendInfo.data
      console.log(response)
      if(response.data && response.data.reset === 'ok'){
        this.setState({ status: "Password Reset, please login with the new password" });
        this.setState({show: true})
      }else{
        this.setState({ status: "Password reset error, please recover resend email again." });
        this.setState({show: true})
      }

    }
    // return recoverAccount(values).then(
    //   (result) => {
    //     // console.log("result", result)
    //     if (result.status === 204 || result.status === 200) {
    //       this.setState({ status: "An recovery email has been sent, click the link in email to set new password" });
    //       this.setState({ show: true })
    //     }
    //   }
    // ).catch(err => {
    //   this.setState({ status: "email passsword error, try again please!" });
    // })
  }

  render() {
    return (
      <Container>
        <Row>
          <div className="whiteSpace"></div>
          <Col md></Col>
          <Col md style={{ flexDirection: "row", alignItems: 'center' }}>
            <SetPassForm onSubmit={(values, dispatch) => this.recover(values, dispatch)} />
          </Col>
          <Col md></Col>
        </Row>
        <Row>
          <div className="whiteSpace">

          </div>
        </Row>

        <Modal show={this.state.show} onHide={this.handelCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Recovery Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.status}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handelCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </Container>
    );
  }
}

export default Recover
